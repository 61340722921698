import {
  Button,
  Box,
  Typography,
  Chip,
  TextField,
} from "@mui/material";

import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { useKeycloak } from "@react-keycloak/web";
import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import HuddleAvatar from "../../components/Huddle/HuddleAvatar.js";

function CreateHuddle() {
  const [goTo, setGoTo] = useState("");
  const [huddleName, setHuddleName] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [huddleMembers, setHuddleMembers] = useState([]);
  const [huddleList, setHuddlelist] = useState([]);
  const [eventDates, setEventDates] = useState([null, null]);
  const { keycloak } = useKeycloak();

  function delMember(m) {
    var position = huddleMembers.indexOf(m);
    if (position >= 0) {
      setHuddleMembers(
        huddleMembers.filter((p) => {
          return p !== m;
        })
      );
    }
  }

  function inviteHuddle() {
    axios
      .post(process.env.REACT_APP_API_URL + "/api/group/invitations", {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
        name: huddleName,
        members: huddleMembers,
      })
      .then((reponse) => {
        setGoTo("/huddle/list");
      });
  }

  if (goTo != "") {
    return <Navigate to={goTo}></Navigate>;
  }
  return (
    <>
      <Typography variant="h4" component="h2" padding={3}>
        Create a Huddle
      </Typography>
      <Box>
        <Typography variant="h5" m={3}>Name of the huddle</Typography>

        <Box>Name</Box>
        <TextField
          value={huddleName}
          onChange={(event) => {
            setHuddleName(event.target.value);
          }}
        ></TextField>
        <Typography variant="h5" m={3}>Members</Typography>
        <Box>Member to add (email)</Box>
        <TextField
          value={currentEmail}
          onChange={(event) => {
            setCurrentEmail(event.target.value);
          }}
        ></TextField>
        <Button
          onClick={() => {
            setHuddleMembers([...huddleMembers, currentEmail]);
            setCurrentEmail("");
          }}
        >
          Add
        </Button>
        <Box width="100%" style={{ border: "1px solid #BBB" }} p={1} mt={3} mb={3}>
          <Box p={1}>
            {huddleMembers.map((m, i) => (
              <>
                <Chip
                  label={m}
                  key={i}
                  onDelete={() => {
                    delMember(m);
                  }}
                ></Chip>{" "}
              </>
            ))}
          </Box>
        </Box>

        <Typography variant="h5" m={3}>Events</Typography>
        <Typography>Date of the first live session : Awareness</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker value={eventDates[0]} onChange={(newValue) => {
            setEventDates([newValue, eventDates[1]]);
          }}></DateTimePicker>

          <Typography>Date of the first live session : Debrief</Typography>
          <DateTimePicker value={eventDates[1]} onChange={(newValue) => {
            setEventDates([eventDates[0], newValue]);
          }}></DateTimePicker>


        </LocalizationProvider>


        <Box sx={{ width: "100%", minHeight: "30px" }} p={2}>
          <Button
            variant="contained"
            sx={{ display: "float", float: "right" }}
            onClick={() => {
              inviteHuddle();
            }}
          >
            Create Huddle & Send Invitations
          </Button>
        </Box>

      </Box >
    </>
  );
}

export default CreateHuddle;
