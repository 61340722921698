import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import axios from "axios";
import Markdown from "react-markdown";
import { Box, Grid, Card, CardMedia, CardContent } from "@mui/material";
import MiniDisc from "../../components/Assessment/MiniDisc.js";
import dprofile2 from "../../content/dprofile2.json";
import clean_scoring from "../../content/clean_scoring.json";
import BatteryGauge from 'react-battery-gauge'
import { MainContext } from "../../context/MainContext.js";
import DiscSvg from "../../components/Assessment/DiscSvg.js";
import { useContext } from "react";

const scaleColors = [
  "#FF0000",
  "#FF0000",
  "#FF3300",
  "#FF6600",
  "#FF9900",
  "#FFCC00",
  "#CCCC00",
  "#99CC00",
  "#66CC00",
  "#33CC00",
  "#00FF00"
]
function PersonalityProfile({ }) {
  const { userInfo } = useContext(MainContext);
  const [profile, setProfile] = useState({})
  const [total, setTotal] = useState(0)
  const [profileType, setProfileType] = useState("D")


  useEffect(() => {
    if (!userInfo.scores) {
      return
    }
    setProfileType(userInfo.scores.computed_style);
    axios.get(process.env.REACT_APP_API_URL + "/api/profiles/" + userInfo.scores.computed_style)
      .then((response) => {
        setProfile(response.data);
        setTotal(Math.max(response.data.motivators.length, response.data.stressors.length))
        console.log('Total' + total);
      })
  }, [userInfo])

  if (profile == {}) return <></>;

  return <>

    <Typography variant="h3" p={3}>{profile.title}</Typography>

    <Typography variant="h4" p={3}>What is DiSC ?</Typography>
    <Grid container>
      <Grid item xs={6}>


        <Typography mb={3}><Markdown>{dprofile2.profile.what}</Markdown></Typography>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5}>
        <Box sx={{ width: '100%', textAlign: "center", paddingTop: "100px" }}>

          {userInfo.scores && userInfo.scores.computed_style !== undefined && (
            <DiscSvg profile={userInfo.scores}></DiscSvg>
          )}

        </Box>
      </Grid>
    </Grid >
    {null &&
      <>
        <Typography variant="h4" p={3}>Your natural inclinations</Typography>
        <Grid container>
          <Grid item xs={7} sx={{ border: "3px solid black" }} p={3}>
            <Typography><strong>Important !</strong> : the scoring is not a evaluation of your level on that particular skill. It's an indication on how those things come naturally to you. The lower the score, the more energy it requires from you. The higher the score, the more you practice it without effort.</Typography>
          </Grid>
        </Grid>
        {
          clean_scoring[profileType].map((s, index) =>
            <Box p={3}>
              <Typography variant="h6" mb={2}>{s.skill}</Typography>
              <Grid container>
                <Grid item xs={2} p={1} pt={0}>
                  <BatteryGauge value={s.score * 10} width="80%" customization={{ batteryMeter: { fill: scaleColors[s.score] } }}></BatteryGauge>
                </Grid>
                <Grid item xs={5}>
                  <Typography sx={{ color: 'text.secondary' }} mb={3}>
                    &#128077; {s.why_strength}
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }}>
                    &#128078;  {s.potential_pitfall}
                  </Typography>

                </Grid>
              </Grid>
            </Box>
          )
        }
      </>
    }



    <Typography variant="h4" p={3}>Your collaboration style</Typography>
    <Box p={3}>

      <Typography mb={3}>{profile.collaborations_intro}</Typography>
      {profile.collaborations && profile.collaborations.map((s, index) =>
        <>
          <Typography variant="h6">{s.title}</Typography>
          <Typography mb={3}>{s.description}</Typography>
        </>
      )}
    </Box>

    <Typography variant="h4" p={3}>Your priorities</Typography>

    {
      profile.priorities && profile.priorities.map((s, i) =>
        <Box p={3}>
          <Card sx={{ width: "50%" }} >
            <CardContent>
              <Typography variant="h5">
                &#128309; {s.title}
              </Typography>
              <Typography>
                {s.description}</Typography>
            </CardContent>
          </Card>
        </Box>
      )
    }

    <Typography variant="h4" p={3}>How you're perceived by others</Typography>
    <Grid container>
      {profile.perceived && [0, 1, 3, 2].map((s, i) =>
        <Grid xs={6} p={1}>
          <Card sx={{ width: "100%" }} >
            <CardMedia sx={{ textAlign: "center" }} p={3} >
              <MiniDisc height="100px" width="100px" quarter={s}></MiniDisc>
            </CardMedia>
            <CardContent>
              <Typography variant="h5">
                {profile.perceived[s].title}
              </Typography>
              <Typography mb={3}>
                {profile.perceived[s].description}</Typography>
              <Typography>
                <strong>Example</strong> : {profile.perceived[s].example}</Typography>

            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>


    <Typography variant="h4" p={3}>Motivators and Stressors</Typography>

    <Grid container>
      <Grid item xs={6}>
        <Typography variant="h5" p={3}>What Motivates You</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h5" p={3}>What Stresses You</Typography>
      </Grid>
      {[...Array(total)].map((e, i) =>
        <>
          <Grid item xs={6}>
            {profile.motivators && profile.motivators.length > i &&
              <Box p={2}>
                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">

                      &#9989; {profile.motivators[i].title}
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                      <Markdown>{profile.motivators[i].description}</Markdown>
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            }
          </Grid >
          <Grid item xs={6}>
            {profile.stressors && profile.stressors.length > i &&
              <Box p={2}>
                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      &#10060; {profile.stressors[i].title}
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                      <Markdown>{profile.stressors[i].description}</Markdown>
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            }

          </Grid>
        </>
      )}

    </Grid >

    <Typography variant="h4" p={3}>Opportunities for your Development</Typography>
    <Grid container>
      {profile.opportunities && profile.opportunities.map((s, index) =>
        <Grid item xs={6} p={1}>
          <Card elevation={1} >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {s.situation}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }} mt={3}>
                <strong>Tendency</strong> : {s.tendency}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }} mt={3}>
                <strong>&#9968; Challenge</strong> : {s.challenge}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }} mt={3}>
                <strong>&#10082; Suggestion</strong> : {s.suggestion}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }} mt={3}>
                <strong>&#128907; Impact</strong> : {s.impact}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }} mt={3}>
                <strong>Insight</strong> : {s.insight}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  </>
}

export default PersonalityProfile;
