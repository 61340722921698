import { CardActions, Typography, Box, Grid, Card, CardContent, CardActionArea, CardAction } from "@mui/material";
import { useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import HuddleAvatar from "../../components/Huddle/HuddleAvatar";
import { useState } from "react";
import axios from "axios";

function ListHuddle() {
  const [huddleList, setHuddlelist] = useState([]);
  const { keycloak } = useKeycloak();

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + "/api/groups",
      {
        headers:
          { Authorization: "Bearer " + keycloak.token }
      })
      .then((response) => {
        setHuddlelist(response.data)
      })
  }, [])

  return <Box>
    <Typography variant="h4" mt={3}>Existing huddles</Typography>
    <Grid container spacing={3}>
      {huddleList !== undefined && huddleList.map((huddle) => (
        <Grid item xs={12}>
          <Card elevation={3} padding={3} sx={{ width: "100%" }}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <CardContent style={{ flexGrow: 1 }}>
                <Box padding={1} alignContent="center">
                  <Typography component="h2" variant="h5" color="primary">
                    Huddle : {huddle.name}
                  </Typography>
                  <Box alignContent={"center"} padding={3}>
                    <HuddleAvatar huddle={huddle}></HuddleAvatar>
                  </Box>
                </Box>
              </CardContent>
              <CardActionArea>
                <CardActions></CardActions>
              </CardActionArea>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Box>

}

export default ListHuddle;
